$defaultColor: #9e5454;

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');

.App {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  max-height:100vh;
}

body{
  background-color: white;
  color: #363f40;
  max-height:100vh;
  position: relative;
}

.header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:60px;
  width:100vw;
  padding-top:10px;
  padding-bottom: 15px;
  background-color: white;
  color:black;
  margin-bottom: 4vh;
  font-size: 13px;
  font-weight: 700;
  box-shadow: 2px 2px 4px 1.5px #c2c2c2;
}

.header img{
  width:190px;
  top:15px;
}

.suggestion-box{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 8px;
  height: 70px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  font-size: 23px;
  font-weight: bold;
  font-family: 'Source Sans Pro', sans-serif;
  background: rgb(255, 255, 255);
}

.filter-toggle{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 100vw;
  font-size: 18px;
  margin-bottom: 15px;
  
}

.filter-box{
  width:60vw;
  margin-left:auto;
  margin-right:auto;
  padding-top:20px;
  padding-left: 20vw;
  padding-right: 20vw;
}

@media (min-width: 1000px) {
    .filter-box{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      margin: auto;
      width:25vw;
    }

    .filter-toggle{
      width:100px;
    }
  
  }  

.filter-toggle label{
  display: flex;
  justify-content: center; 
  align-items: center;
  width:50%;
  font-family: 'Source Sans Pro', sans-serif;
}

.toggle-switch{
  display: flex;
  justify-content: center; 
  width:50%;
}

.suggestion-button{
  width: 23vw;
  max-width: 200px;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  font-size: 14px;
  background-color: white;
  border: 1px solid #a5a5a5;
  // box-shadow: 2px 2px 1px 1.5px #c7c7c7;
  border-radius: 5px;
  font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;
}

.button-container{
  display: flex;
  justify-content: center;
}

// .suggestion-button:hover{
//   color:$defaultColor;
//   background-color: white;
// }

.button{
  display: flex;
  width: 100vw;
  height: 10vh;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  background-color: white;
  color:black;
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  font-size: 20px;
  border-top:1px solid grey;
}

.selected-button{
  color:white;
  background-color: $defaultColor;
}

.fade-out{
    opacity:0;
    transition: opacity 0.5s;
}

.fade-in{
    opacity:1;
    transition: opacity 0.5s;
}

.login-page{
  display: flex;
  flex-direction: column;
  background-color: $defaultColor;
  color: white;
  height:100vh;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.login-page img{
  width:190px;
  margin-bottom: 30px;
}

.login-page h1{
  width:80vw;
  margin-right: auto;
  margin-left: auto;
  margin-top:20vh;
}

.login-form{
  display: flex;
  flex-direction: column;
  width:60vw;
  background-color: $defaultColor;
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.login-form input{
  margin-bottom: 15px;
}

.login-button{
  background-color: white;
  color: #4d4d4d;
  font-weight: bold;
  width: 100px;
  height:50px;
  border-radius: 5px;
  font-family: 'Source Sans Pro', sans-serif;
  margin-left: auto;
  margin-right: auto;
}

.modal-container{
  height:100vh;
  width:100vw;
  position: absolute;
  top: 0;
  z-index:1;
  display:flex;
  flex-direction: row;
  align-items: center;
}

.modal-content{
  z-index:2;
  height:72vh;
  width:90vw;
  background-color: white;
  color:black;
  margin:auto;
  border-radius: 5px;
  // border: 1px solid rgb(172, 172, 172);
  box-shadow: 0px 0px 5px 0.5px rgb(184, 183, 183);
  font-weight: 200;
}

.recipe-details{
  height:60vh;
  overflow: scroll;
  border: 1px solid rgb(211, 211, 211);
}

.modal-content h3{
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
}

.ingredients{
  font-family: 'Source Sans Pro', sans-serif;
  padding:15px;
}

.steps{
  font-family: 'Source Sans Pro', sans-serif;
  padding-left: 15px;
  padding-right: 15px;
}

.close-button{
  width: 23vw;
  max-width: 200px;
  height: 45px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  display: block;
  background-color: white;
  border: 1px solid #a5a5a5;
  // box-shadow: 2px 2px 1px 1.5px #c7c7c7;
  border-radius: 5px;
  font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;
}

.recipe-step{
  margin-bottom: 10px;
}
